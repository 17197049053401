@import './../../../styles/mixins.scss';

.errorBlock {
  display: flex;
  flex-wrap: wrap;
  &__img {
    position: relative;
    width: 170px;
    height: auto;
    margin: 0 20px 0 0;
    background-color: #f0f0f0;
    @include max-screen-980 {
      width: 100%;
      height: 117px;
      margin: 0 0 10px 0;
    }
    @include max-screen-768 {
      width: 170px;
      height: 113px;
      margin: 0 20px 0 0;
    }

    @include max-screen-530 {
      width: 100%;
      height: 117px;
      margin: 0 0 10px 0;
    }
    &:before {
      content: '';
      position: absolute;
      width: 124px;
      height: 97px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: url('/icons/book-icon-404.svg') no-repeat 0 0;
      background-size: cover;
      @include max-screen-980 {
        width: 118px;
        height: 92px;
      }
      @include max-screen-768 {
        width: 124px;
        height: 97px;
      }
      @include max-screen-530 {
        width: 118px;
        height: 92px;
      }
    }
  }
  &__desk {
    flex: 1;
  }
  &__title {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.3;
    color: #000;
    margin-bottom: 10px;
  }
  &__txt {
    font-size: 14px;
    color: #575757;
    margin-bottom: 30px;
    @include max-screen-980 {
      margin-bottom: 15px;
    }
  }
  &__btn {
    position: relative;
    display: inline-block;
    text-align: center;
    border-radius: 3px;
    padding: 8px 32px 8px 16px;
    font-size: 14px;
    text-decoration: none;
    transition: all 0.3s ease;
    position: relative;
    background: #1a5f9b;
    color: #fff;
    &:hover {
      background: #1f73bc;
      color: #fff;
    }
    &::before {
      position: absolute;
      content: '';
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      width: 11px;
      height: 11px;
      background-size: cover;
      background: url('/icons/arrow-white.svg') no-repeat 0 0;
      transition: opacity 0.3s ease;
    }
  }
}
